/*
* === FLEXBOX MIXINS ===
*/
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(5px); } }

@keyframes PopIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  70% {
    opacity: 1;
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes Loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif; }

.main-container {
  min-height: 100vh;
  overflow: hidden; }

.container {
  max-width: 133.4rem;
  width: 100%;
  margin: 0 auto; }

.full-width-container {
  padding-right: 3.5rem;
  padding-left: 3.5rem; }

.full-width-container-2 {
  padding-right: 7.4rem;
  padding-left: 7.4rem; }

a:link,
a:visited {
  text-decoration: none; }

::selection {
  background-color: #222e64;
  color: #ffffff; }

.hidden {
  display: none !important; }

.light-bg {
  background-color: #f4f4f4; }

.text-center {
  text-align: center; }

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none; }

.border-btm-gray {
  border-bottom: 1px solid #bfbcbc; }

.border-top-gray {
  border-top: 1px solid #bfbcbc; }

.icon-m-left {
  margin-left: 0.3rem; }

.no-overflow {
  height: 100%;
  overflow-y: hidden; }

.overflow {
  overflow-y: auto;
  height: calc(100vh - 6rem); }

.not-visible {
  visibility: hidden; }

.block {
  display: block; }

.inline {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px); }

.half-circle-btn:link,
.half-circle-btn:visited {
  color: #222e64;
  font-size: 1.6rem;
  font-weight: 700;
  width: 31.5rem;
  height: 31.5rem;
  line-height: 31.5rem;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in;
  background-color: #69e2d6;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }
  .half-circle-btn:link:hover,
  .half-circle-btn:visited:hover {
    background-color: #25c0b1; }

.half-circle-btn-left {
  transform: translateX(-50%);
  padding-right: 65px;
  text-align: right; }

.half-circle-btn-right {
  transform: translateX(50%);
  padding-left: 51px;
  text-align: left; }

.sign-in {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.9s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  cursor: pointer; }
  .sign-in__button {
    display: inline-block;
    width: 31.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.9rem;
    color: #ffffff;
    text-align: left;
    padding: 0 2rem;
    transition: all 0.2s ease-in;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; }
    .sign-in__button:hover {
      cursor: pointer; }
    .sign-in__button:not(:last-child) {
      margin-bottom: 1.5rem; }
  .sign-in__input {
    width: 31.5rem;
    font-size: 2.1rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #fff;
    font-family: inherit;
    background-color: transparent;
    color: #ffffff;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .sign-in__input::placeholder {
      color: #ffffff96; }
    .sign-in__input:focus {
      outline: none; }
  .sign-in__icon {
    margin-right: 2.5rem; }
  .sign-in__error {
    color: #fa3959; }
  .sign-in--social-label {
    color: #ffffff; }
    .sign-in--social-label:hover {
      cursor: default; }
  .sign-in--forgot-label {
    color: #ffffff;
    margin-bottom: 1rem; }
  .sign-in--email {
    color: #565657;
    margin-top: 2.5rem;
    background-color: #ffffff; }
    .sign-in--email:hover {
      background-color: #ebebeb; }
    .sign-in--email .fa-envelope {
      color: #222e64; }
  .sign-in--facebook {
    background-color: #405991; }
    .sign-in--facebook:hover {
      background-color: #344875; }
  .sign-in--google {
    background-color: #e8473d; }
    .sign-in--google:hover {
      background-color: #e6362b; }
  .sign-in--twitter {
    background-color: #69aae6; }
    .sign-in--twitter:hover {
      background-color: #4696e0; }
  .sign-in--github {
    background-color: #626467; }
    .sign-in--github:hover {
      background-color: #4e5052; }
  .sign-in--microsoft {
    background-color: #158eea; }
    .sign-in--microsoft:hover {
      background-color: #1277c5; }

.sign-in.visible {
  max-height: 120rem;
  opacity: 1; }

.sign-up {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.9s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  cursor: pointer; }
  .sign-up__button {
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.9rem;
    color: #ffffff;
    text-align: center;
    border: none;
    transition: all 0.2s ease-in;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; }
    .sign-up__button:hover {
      cursor: pointer; }
  .sign-up__socials-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-top: 2rem; }
  .sign-up__input {
    width: 31.5rem;
    font-size: 2.1rem;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: inherit;
    border-color: #fff;
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ffffff; }
    .sign-up__input::placeholder {
      color: #ffffff96; }
    .sign-up__input:focus {
      outline: none; }
  .sign-up__icon {
    margin: 0; }
  .sign-up__icon-email {
    margin-right: 2.5rem; }
  .sign-up__error {
    color: #fa3959; }
  .sign-up--social-label {
    color: #ffffff; }
    .sign-up--social-label:hover {
      cursor: default; }
  .sign-up--email {
    color: #565657;
    margin-top: 2.5rem;
    width: 31.5rem;
    height: 5.5rem;
    text-align: left;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
    background-color: #ffffff; }
    .sign-up--email:hover {
      background-color: #ebebeb; }
    .sign-up--email .fa-envelope {
      color: #222e64; }
  .sign-up--facebook {
    background-color: #405991; }
    .sign-up--facebook:hover {
      background-color: #344875; }
  .sign-up--google {
    background-color: #e8473d; }
    .sign-up--google:hover {
      background-color: #e6362b; }
  .sign-up--twitter {
    background-color: #69aae6; }
    .sign-up--twitter:hover {
      background-color: #4696e0; }
  .sign-up--github {
    background-color: #626467; }
    .sign-up--github:hover {
      background-color: #4e5052; }
  .sign-up--microsoft {
    background-color: #158eea; }
    .sign-up--microsoft:hover {
      background-color: #1277c5; }

.sign-up.visible {
  max-height: 120rem;
  opacity: 1; }

.forgot-password {
  overflow: hidden;
  transition: all 0.9s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  cursor: pointer; }
  .forgot-password__button {
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
    line-height: 5.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.9rem;
    color: #ffffff;
    text-align: center;
    border: none;
    transition: all 0.2s ease-in;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; }
    .forgot-password__button:hover {
      cursor: pointer; }
  .forgot-password__socials-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-top: 2rem; }
  .forgot-password__input {
    width: 31.5rem;
    font-size: 2.1rem;
    border-top: none;
    border-left: none;
    border-right: none;
    display: block;
    font-family: inherit;
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ffffff; }
    .forgot-password__input:focus {
      outline: none; }
  .forgot-password__error {
    color: #fa3959; }
  .forgot-password--social-label {
    color: #ffffff; }
    .forgot-password--social-label:hover {
      cursor: default; }
  .forgot-password--email {
    color: #565657;
    margin-top: 2.5rem;
    width: 31.5rem;
    height: 5.5rem;
    text-align: left;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
    background-color: #ffffff; }
    .forgot-password--email:hover {
      background-color: #ebebeb; }
    .forgot-password--email .fa-envelope {
      color: #222e64; }

.add-project {
  padding-top: 22.9rem;
  padding-bottom: 13rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .add-project__btn {
    display: block;
    width: 22.7rem;
    height: 22.7rem;
    transition: all 0.3s ease;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-box-shadow: 0 0 5.2rem 0.5rem rgba(108, 108, 108, 0.4);
    -moz-box-shadow: 0 0 5.2rem 0.5rem rgba(108, 108, 108, 0.4);
    box-shadow: 0 0 5.2rem 0.5rem rgba(108, 108, 108, 0.4); }
    .add-project__btn:hover {
      -webkit-box-shadow: 0 0 1.2rem 0.5rem rgba(108, 108, 108, 0.8);
      -moz-box-shadow: 0 0 1.2rem 0.5rem rgba(108, 108, 108, 0.8);
      box-shadow: 0 0 1.2rem 0.5rem rgba(108, 108, 108, 0.8); }
  .add-project__btn.no-shadow {
    transition: all 0.3s ease-in;
    background-color: #ffffff;
    box-shadow: none; }
    .add-project__btn.no-shadow:hover {
      box-shadow: none;
      background-color: #5a74d7; }
    .add-project__btn.no-shadow:hover .add-project__icon,
    .add-project__btn.no-shadow:hover .add-project__text {
      color: #ffffff; }
  .add-project__icon {
    font-size: 6rem;
    color: #5a74d7;
    flex: 1 1 65%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .add-project__text {
    color: #5a74d7;
    margin-top: 1.5rem;
    flex: 1 1 35%; }

.add-project-wrapper .add-project__btn {
  width: 25rem;
  height: 25rem; }

.section-header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 2.6rem;
  padding-bottom: 0.6rem; }
  .section-header__info {
    font-family: "Roboto", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.53px; }
    .section-header__info .fa-angle-down {
      margin-left: 1.1rem; }
  .section-header__info.light-bg {
    color: #565657; }
  .section-header__filters {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .section-header__filters.light-bg {
    color: #565657; }
  .section-header__filters-info {
    font-size: 1.2rem;
    font-weight: 700;
    margin-right: 0.8rem; }
    .section-header__filters-info .fa-filter {
      margin-right: 0.8rem;
      font-size: 1.6rem; }
    .section-header__filters-info .fa-filter--blue {
      color: #69e2d6; }
  .section-header__filter-options .options {
    outline: none;
    background-color: transparent;
    border: none;
    color: inherit;
    font-style: italic; }
    .section-header__filter-options .options option {
      color: #222e64;
      font-style: normal; }

.section-header--primary {
  border-bottom: 1px solid #202020; }

.section-header--secondary {
  color: #ffffff;
  border-bottom: 1px solid #ffffff; }

.template {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-basis: 20%;
  flex-basis: 20%;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  font-weight: 300; }
  .template__link {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: block;
    overflow: hidden; }
    .template__link img {
      display: block;
      width: 22.6rem;
      height: 22.6rem; }
  .template__name {
    font-size: 1.8rem; }
  .template__client {
    font-size: 1rem; }
  .template__count {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #ffffff;
    background-color: #5a74d7;
    width: 4.5rem;
    height: 4.5rem;
    position: absolute;
    top: 1.8rem;
    right: 2rem;
    z-index: 2;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-box-shadow: 0 7px 7px 2px rgba(32, 32, 32, 0.5);
    -moz-box-shadow: 0 7px 7px 2px rgba(32, 32, 32, 0.5);
    box-shadow: 0 7px 7px 2px rgba(32, 32, 32, 0.5); }
  .template__count--animated {
    animation: PopIn 0.7s ease-out 2.5s;
    animation-fill-mode: backwards; }
    .template__count--animated .num {
      transition: all 0.2s linear;
      transition-delay: 0.3s;
      opacity: 1;
      font-weight: 700;
      font-size: 1.6rem; }
  .template__count--animated.animated {
    opacity: 1;
    transform: scale(1); }
    .template__count--animated.animated .num {
      opacity: 1; }
  .template__count.red {
    background-color: #fa3959; }
  .template__count.green {
    background-color: #39b54a; }
  .template__project-name {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-top: 1rem; }
  .template__project-category {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.0068rem; }
  .template .wrapper {
    position: relative; }

.project-btn {
  position: relative;
  width: 25rem;
  height: 25rem; }
  .project-btn__progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .project-btn__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: cover;
    object-position: 50% 50%;
    padding-top: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .project-btn__link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 250px;
    height: 250px;
    box-sizing: border-box;
    background: none;
    border: none;
    cursor: pointer;
    padding-top: 3.3%;
    padding-right: 3.3%;
    padding-bottom: 3.3%;
    padding-left: 3.3%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
    .project-btn__link img {
      opacity: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease-in;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
      .project-btn__link img:hover {
        opacity: 0.6; }
  @media only screen and (max-width: 770px) {
    .project-btn {
      width: 20rem;
      height: 20rem; } }
  @media only screen and (max-width: 620px) {
    .project-btn {
      width: 28rem;
      height: 28rem; } }

.circular-btn {
  position: relative;
  color: #202020;
  transition: all 0.3s ease;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  align-items: center;
  text-align: left;
  padding: 2rem;
  width: 100%;
  height: 100%; }
  .circular-btn__icon {
    font-size: 1.8rem; }
  .circular-btn__name {
    font-size: 1.6rem;
    line-height: calc(1.6rem + 0.3rem);
    transition: all 0.2s ease;
    margin: 0;
    font-weight: 700; }
  .circular-btn__client {
    font-size: 1.2rem; }
  .circular-btn .btn-indicators {
    margin-top: 4rem;
    font-size: 1.3rem;
    font-weight: 700;
    color: #69e2d6;
    letter-spacing: 0.1rem; }
    .circular-btn .btn-indicators__vl {
      height: 3.3rem;
      border-left: 1px solid #69e2d6; }
    .circular-btn .btn-indicators__fav-count {
      padding-right: 1.6rem; }
    .circular-btn .btn-indicators__views {
      padding-left: 1.6rem; }
    .circular-btn .btn-indicators .fas,
    .circular-btn .btn-indicators .far {
      margin-right: 0.7rem;
      font-size: 1.4rem; }
  .circular-btn .hover-arrow {
    opacity: 0;
    font-size: 2.8rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0rem);
    transition: all 0.2s ease-out; }
  .circular-btn:hover .hover-arrow {
    opacity: 1;
    transform: translate(-50%, -2rem); }

.circular-btn--black {
  color: #202020;
  border-top: 1px solid #5b5b5b;
  border-right: 1px solid #5b5b5b;
  border-left: 1px solid #5b5b5b;
  border-bottom: 1px solid #5b5b5b; }
  .circular-btn--black .hover-arrow {
    color: #202020; }
  .circular-btn--black:hover {
    background-color: #ffffff; }

.circular-btn--white {
  color: #ffffff;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff; }
  .circular-btn--white .hover-arrow {
    color: #69e2d6; }
  .circular-btn--white:hover {
    border-color: #69e2d6;
    background-color: #222e64; }
  .circular-btn--white .circular-btn__name {
    margin-top: 2.2rem;
    margin-bottom: 0.3rem; }

.circular-btn--active {
  color: #202020;
  border-top: 1px solid #39b54a;
  border-right: 1px solid #39b54a;
  border-left: 1px solid #39b54a;
  border-bottom: 1px solid #39b54a;
  background-color: #ffffff; }
  .circular-btn--active .circular-btn__name {
    margin-top: 3.5rem;
    margin-bottom: 0.3rem; }

.accordion {
  font-family: "Roboto", sans-serif; }
  .accordion input[name='panel'] {
    display: none; }
  .accordion__label {
    position: relative;
    display: block;
    cursor: pointer;
    transition: all, 0.2s ease;
    padding-top: 1.8rem;
    padding-right: 3rem;
    padding-bottom: 1.7rem;
    padding-left: 2.2rem;
    display: grid;
    grid-template-columns: auto 1fr auto auto auto auto;
    align-items: center; }
    @media only screen and (max-width: 620px) {
      .accordion__label {
        grid-template: 1fr 1fr / auto 1fr; } }
    .accordion__label:hover {
      background-color: #f3f3f3; }
    .accordion__label img {
      margin-right: 2rem; }
  .accordion .toggle-chevron + label:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: '\f078';
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%); }
  .accordion input[name='panel']:checked ~ .accordion__content {
    max-height: 200rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .accordion input.toggle-chevron:checked + label:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    content: '\f077'; }
  .accordion .accordion__content {
    background-color: #ffffff;
    line-height: 2rem;
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87); }

.remove-option-btn {
  margin-left: auto;
  border: 0;
  background-color: transparent;
  cursor: pointer; }
  .remove-option-btn .fa-times {
    font-size: 1.8rem;
    color: #5b5b5b; }

.template-options {
  color: #4f4f4f;
  font-family: "Roboto", sans-serif; }
  .template-options__panel {
    background-color: #ffffff;
    border-top: 2px dashed #ffffff;
    border-right: 2px dashed #ffffff;
    border-bottom: 2px dashed #ffffff;
    border-left: 2px dashed #ffffff; }
    .template-options__panel .drag-panel-btn {
      color: #5b5b5b;
      font-size: 1.8rem;
      cursor: grab; }
    .template-options__panel input[name='panel']:checked ~ .accordion__content {
      padding-right: 2.2rem; }
  .template-options__panel.dragging {
    border-top: 2px dashed #222e64;
    border-right: 2px dashed #222e64;
    border-bottom: 2px dashed #222e64;
    border-left: 2px dashed #222e64;
    opacity: 0.7; }
  .template-options__label {
    padding: 0.8rem;
    border-bottom: 2px solid #eaeaea; }
    .template-options__label .fa-chevron-down {
      font-size: 1.8rem; }
  .template-options__toggle-accordion:checked ~ .template-options__label .fa-chevron-right {
    opacity: 0; }
  .template-options__toggle-accordion ~ .template-options__label .drag-panel-btn,
  .template-options__toggle-accordion:checked ~ .template-options__label .chevron-down-icon {
    display: initial; }
  .template-options__toggle-accordion:checked ~ .template-options__label .drag-panel-btn,
  .template-options__toggle-accordion ~ .template-options__label .chevron-down-icon {
    display: none; }
  .template-options__number {
    width: 2.8rem;
    height: 2.8rem;
    border-top: 1px solid #202020;
    border-right: 1px solid #202020;
    border-bottom: 1px solid #202020;
    border-left: 1px solid #202020;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1.4rem;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .template-options__question {
    font-size: 1.8rem; }
  .template-options__input {
    width: 43%;
    font-size: 2.1rem;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: inherit;
    background-color: transparent;
    color: #4f4f4f;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .template-options__input:focus {
      outline: none; }
  .template-options__chevron-right {
    padding: 0 1rem;
    transition: all 0.2s ease;
    font-size: 1.6rem; }
  .template-options__question-btn {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.028rem;
    color: #5b5b5b;
    transition: all 0.2s ease; }
    .template-options__question-btn:hover {
      color: #5a74d7; }
    .template-options__question-btn .fas {
      font-size: 1.8rem;
      margin-left: 1rem; }
  .template-options .edit-btn {
    margin-right: 1.8rem;
    margin-left: auto; }
  .template-options .delete-btn {
    margin-right: 1.8rem; }
    .template-options .delete-btn .fas {
      margin-left: 4px; }
  .template-options__accordion {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .template-options__accordion .drag-panel-btn {
      margin-left: auto; }
  .template-options__type {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 1rem; }
    .template-options__type .fas {
      margin-bottom: 1rem;
      color: #7c7c7c; }
    .template-options__type p {
      font-size: 1.2rem;
      font-weight: 700;
      color: #7c7c7c;
      line-height: calc(0.2rem + 1.2rem);
      white-space: pre-wrap; }
  .template-options__type.yes-no-padding {
    padding-left: 0.5rem; }
  .template-options__list {
    margin-left: 4.5rem;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    .template-options__list .image-form {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
    .template-options__list .radio-container {
      position: relative;
      cursor: pointer;
      font-size: 1.4rem;
      line-height: 2.3rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding-left: 3.5rem; }
      .template-options__list .radio-container input {
        display: none; }
      .template-options__list .radio-container:hover input ~ .checkmark {
        background-color: #ffffff; }
      .template-options__list .radio-container input:checked ~ .checkmark {
        background-color: #314bad;
        color: #ffffff;
        border-color: #314bad; }
      .template-options__list .radio-container img {
        width: 10.8rem;
        height: 10.8rem; }
      .template-options__list .radio-container .checkmark {
        font-size: 1.1rem;
        font-weight: 700;
        color: #4f4f4f;
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 50%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-top: 2px solid #202020;
        border-right: 2px solid #202020;
        border-bottom: 2px solid #202020;
        border-left: 2px solid #202020; }
    .template-options__list .answers-container {
      margin-bottom: 1.3rem;
      display: grid;
      grid-template-columns: 4rem auto 1fr;
      align-items: center; }
      .template-options__list .answers-container .container-text {
        font-size: 1.5rem; }
        .template-options__list .answers-container .container-text:not(:last-child) {
          margin: 0; }
      .template-options__list .answers-container .opt-answer {
        width: 30rem;
        height: 3.6rem;
        padding: 0.6rem;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-top: 2px solid #202020;
        border-right: 2px solid #202020;
        border-bottom: 2px solid #202020;
        border-left: 2px solid #202020; }
        .template-options__list .answers-container .opt-answer:focus {
          border: 2px solid #69e2d6 !important;
          outline-offset: 0px !important;
          outline: none !important;
          -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
          -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
          box-shadow: 0 0 0.2rem 0 #69e2d6; }
      .template-options__list .answers-container .opt-answer-error {
        font-size: 1.2rem;
        color: #fa3959;
        display: block; }
      .template-options__list .answers-container .delete-answer-btn {
        transition: all 0.2s ease;
        font-family: "Roboto", sans-serif;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        padding-top: 0.2rem;
        padding-right: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.2rem;
        margin-left: 1rem; }
        .template-options__list .answers-container .delete-answer-btn .fas {
          margin-left: 4px; }
        .template-options__list .answers-container .delete-answer-btn:hover {
          color: #5a74d7; }
      .template-options__list .answers-container .upload-img {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 10.8rem; }
        .template-options__list .answers-container .upload-img__preview {
          width: 10.8rem;
          height: 10.8rem;
          object-fit: cover;
          background-color: #f4f4f4;
          border-top: 2px solid #222e64;
          border-right: 2px solid #222e64;
          border-left: 2px solid #222e64; }
        .template-options__list .answers-container .upload-img__input {
          height: 0;
          overflow: hidden;
          width: 0; }
        .template-options__list .answers-container .upload-img label {
          color: #222e64;
          background-color: #ffffff;
          font-size: 1.5rem;
          font-weight: 700;
          width: 10.8rem;
          position: relative;
          cursor: pointer;
          display: inline-block;
          transition: all 0.2s ease;
          border-top: 2px solid #222e64;
          border-right: 2px solid #222e64;
          border-bottom: 2px solid #222e64;
          border-left: 2px solid #222e64;
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center; }
          .template-options__list .answers-container .upload-img label:hover {
            background-color: #222e64;
            color: #ffffff; }
    .template-options__list .images-type-container:not(:first-child) {
      margin-left: 4.8rem; }
    .template-options__list .images-type-container .delete-answer-btn {
      margin-top: 1rem;
      margin-left: 0;
      width: 10rem;
      color: #222e64; }
      .template-options__list .images-type-container .delete-answer-btn .fas {
        margin-left: 4px; }
    .template-options__list .images-type-container .container-img {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0; }
    .template-options__list .add-answer-container {
      display: flex;
      align-items: center; }
      .template-options__list .add-answer-container .add-answer-btn {
        width: 2.3rem;
        height: 2.3rem;
        color: #5a74d7;
        transition: all 0.2s ease;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        border-top: 2px solid #5a74d7;
        border-right: 2px solid #5a74d7;
        border-bottom: 2px solid #5a74d7;
        border-left: 2px solid #5a74d7;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%; }
        .template-options__list .add-answer-container .add-answer-btn:hover {
          background-color: #5a74d7;
          color: #ffffff; }
        .template-options__list .add-answer-container .add-answer-btn .fa-plus {
          font-size: 1rem; }
      .template-options__list .add-answer-container span {
        color: #5a74d7;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.3rem;
        margin-left: 1rem; }
    .template-options__list .container-text {
      font-size: 1.8rem;
      display: block; }
      .template-options__list .container-text:not(:last-child) {
        margin-bottom: 1.3rem; }
      .template-options__list .container-text .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
    .template-options__list .container-img {
      display: inline-block;
      margin-right: 4.8rem; }
      .template-options__list .container-img .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }

.large-btn-container {
  padding-top: 0.7rem;
  padding-right: 0.5rem;
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  background-color: #f4f4f4;
  margin-top: auto;
  width: 100%; }
  .large-btn-container .large-btn {
    width: 100%;
    height: 8.1rem;
    transition: all 0.2s ease;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
  .large-btn-container .simple-btn {
    font-family: "Roboto Slab", serif;
    font-size: 2.1rem;
    background-color: #69e2d6;
    color: #373737;
    cursor: pointer;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .large-btn-container .simple-btn:hover {
      background-color: #a9eee8; }
  .large-btn-container .next-phase-btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 1.4rem;
    padding-left: 1.4rem; }
    .large-btn-container .next-phase-btn .vote {
      font-family: "Roboto", sans-serif; }
    .large-btn-container .next-phase-btn .validation {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 1.8rem;
      text-transform: uppercase;
      margin-left: 1.2rem; }
    .large-btn-container .next-phase-btn .info {
      font-size: 2.1rem;
      margin-right: 1.8rem;
      margin-left: auto; }
    .large-btn-container .next-phase-btn .fa-long-arrow-alt-right {
      font-size: 2.8rem; }
  .large-btn-container .next-phase-btn.isDisabled {
    background-color: #e1e1e1;
    cursor: not-allowed;
    opacity: 0.5;
    color: #3f3f3f; }
    .large-btn-container .next-phase-btn.isDisabled .vote {
      font-size: 2.1rem;
      font-weight: 700;
      color: #3f3f3f;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-top: 2px solid #6c6c6c;
      border-right: 2px solid #6c6c6c;
      border-bottom: 2px solid #6c6c6c;
      border-left: 2px solid #6c6c6c; }
  .large-btn-container .next-phase-btn.isEnabled {
    background-color: #69e2d6;
    cursor: pointer;
    color: #373737; }
    .large-btn-container .next-phase-btn.isEnabled .vote {
      font-size: 2.1rem;
      font-weight: 700;
      color: #373737;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-top: 2px solid #202020;
      border-right: 2px solid #202020;
      border-bottom: 2px solid #202020;
      border-left: 2px solid #202020; }
    .large-btn-container .next-phase-btn.isEnabled:hover {
      background-color: #a9eee8; }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.7s ease; }
  .modal-overlay .close-modal {
    position: absolute;
    top: 5rem;
    right: 5rem;
    color: #ffffff;
    font-size: 4rem;
    cursor: pointer;
    transition: color 0.2s ease; }
    .modal-overlay .close-modal:hover {
      color: #69e2d6; }

.modal-overlay.active {
  visibility: visible;
  opacity: 1; }

.modal-overlay.active + .modal {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%); }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 98rem;
  height: 65rem;
  color: #ffffff;
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-out;
  transform: translate(-50%, -30%); }
  @media only screen and (max-width: 620px) {
    .modal {
      width: 90vw; } }
  .modal .slider-nav-left,
  .modal .slider-nav-right {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    font-size: 4rem;
    cursor: pointer;
    transition: color 0.2s ease; }
    .modal .slider-nav-left:hover,
    .modal .slider-nav-right:hover {
      color: #69e2d6; }
  .modal .slider-nav-left {
    left: -10%; }
  .modal .slider-nav-right {
    right: -10%; }
  .modal__slide:focus {
    outline: 0; }
  .modal__slide-selection {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 2rem;
    font-family: "Roboto", sans-serif; }
    .modal__slide-selection p {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 1.5rem; }
    .modal__slide-selection .logo-selection-btn {
      font-size: 2.1rem;
      font-weight: 700;
      color: #ffffff;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      cursor: pointer; }
    .modal__slide-selection .logo-selection-btn.selected {
      color: #ffffff;
      border-color: #314bad;
      background-color: #314bad; }
  .modal__slide-img {
    display: block;
    width: 100%;
    height: 58.5rem;
    object-fit: cover;
    object-position: 50% 50%;
    border-top: 1rem solid #ffffff;
    border-right: 1rem solid #ffffff;
    border-bottom: 1rem solid #ffffff;
    border-left: 1rem solid #ffffff; }

.loader-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center; }

.loader-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.loader-roller div {
  animation: Loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px; }

.loader-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #222e64;
  margin: -4px 0 0 -4px; }

.loader-roller div:nth-child(1) {
  animation-delay: -0.036s; }

.loader-roller div:nth-child(1):after {
  top: 63px;
  left: 63px; }

.loader-roller div:nth-child(2) {
  animation-delay: -0.072s; }

.loader-roller div:nth-child(2):after {
  top: 68px;
  left: 56px; }

.loader-roller div:nth-child(3) {
  animation-delay: -0.108s; }

.loader-roller div:nth-child(3):after {
  top: 71px;
  left: 48px; }

.loader-roller div:nth-child(4) {
  animation-delay: -0.144s; }

.loader-roller div:nth-child(4):after {
  top: 72px;
  left: 40px; }

.loader-roller div:nth-child(5) {
  animation-delay: -0.18s; }

.loader-roller div:nth-child(5):after {
  top: 71px;
  left: 32px; }

.loader-roller div:nth-child(6) {
  animation-delay: -0.216s; }

.loader-roller div:nth-child(6):after {
  top: 68px;
  left: 24px; }

.loader-roller div:nth-child(7) {
  animation-delay: -0.252s; }

.loader-roller div:nth-child(7):after {
  top: 63px;
  left: 17px; }

.loader-roller div:nth-child(8) {
  animation-delay: -0.288s; }

.loader-roller div:nth-child(8):after {
  top: 56px;
  left: 12px; }

.Toastify__toast.Toastify__toast--success {
  background: #39b54a; }

.button {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  min-width: 14rem;
  padding: 1.3rem 2.2rem;
  transition: all 0.2s ease;
  border-radius: 3px;
  margin-bottom: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }
  .button:hover {
    cursor: pointer; }
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .button i {
    margin-left: 1rem; }
  .button__primary {
    background-color: #222e64;
    color: #ffffff;
    border: 2px solid #222e64; }
    .button__primary:hover {
      background-color: #5a74d7;
      border-color: #5a74d7; }
      .button__primary:hover:disabled {
        background-color: #222e64;
        border-color: #222e64; }
  .button__icon {
    background-color: #222e64;
    color: #ffffff;
    border: 2px solid #222e64;
    min-width: auto; }
    .button__icon:hover {
      background-color: #5a74d7;
      border-color: #5a74d7; }
      .button__icon:hover:disabled {
        background-color: #222e64;
        border-color: #222e64; }
  .button__secondary {
    background-color: transparent;
    color: #222e64;
    border: 2px solid #222e64; }
    .button__secondary:hover {
      background-color: #222e64;
      color: #ffffff; }
      .button__secondary:hover:disabled {
        background-color: transparent;
        color: #222e64; }
  .button__lightGreen {
    background-color: #69e2d6;
    color: #222e64;
    border: 2px solid #222e64; }
    .button__lightGreen:hover {
      background-color: #222e64;
      color: #ffffff; }
      .button__lightGreen:hover:disabled {
        background-color: transparent;
        color: #222e64; }
  .button__tertiary {
    background-color: transparent;
    color: #7d7d7d;
    border: none; }
    .button__tertiary:hover {
      color: #222e64; }
      .button__tertiary:hover:disabled {
        color: #7d7d7d;
        opacity: 0.6; }

.main-container {
  font-family: 'Roboto', sans-serif; }

.logged-in-container {
  display: grid;
  grid-template-columns: 200px auto;
  font-family: 'Roboto', sans-serif; }

.minimized-container {
  display: grid;
  grid-template-columns: 80px auto;
  font-family: 'Roboto', sans-serif; }

.page-container {
  margin: 3rem 3.5rem;
  height: -webkit-fill-available; }

.side-navigation {
  background-color: #222e64;
  padding-top: 2rem; }
  .side-navigation a {
    padding: 2rem 3rem;
    display: block;
    color: #ffffff;
    transition: all 0.2s ease; }
    .side-navigation a .nav-icon {
      margin-right: 1.5rem; }
    .side-navigation a span {
      opacity: 0.7;
      transition: all 0.2s ease; }
    .side-navigation a:hover {
      background-color: #ffffff0a; }
      .side-navigation a:hover span {
        opacity: 1; }
  .side-navigation .feedback-btn {
    margin: 2rem 3rem;
    color: #ffffff;
    border: 2px solid #5a74d7;
    background-color: #5a74d7;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    padding: 1.3rem 2.2rem;
    transition: all 0.2s ease;
    border-radius: 3px; }
    .side-navigation .feedback-btn:hover {
      cursor: pointer;
      background-color: #5a74d7b8;
      border-color: #5a74d7b8; }
    .side-navigation .feedback-btn.minimized {
      padding: 1rem 1.4rem;
      margin-left: 2rem; }
  .side-navigation a.active {
    background-color: #ffffff14; }
    .side-navigation a.active span {
      opacity: 1; }

@media only screen and (max-width: 620px) {
  .logged-in-container {
    grid-template-columns: 1fr; }
  .minimized-container {
    grid-template-columns: 1fr; }
  .page-container {
    margin: 0; }
  .mobile-expanded {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99; }
  .side-navigation {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99; } }

.content-container {
  box-shadow: 0 0px 15px #00000012;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  overflow: hidden; }
  @media only screen and (max-width: 620px) {
    .content-container {
      border-radius: 0; } }
  .content-container.hoverable:hover {
    cursor: pointer; }
  .content-container.hidden {
    display: none; }
  .content-container.full-height {
    height: 100%; }
  .content-container__inner {
    display: block;
    height: 100%;
    padding: 4rem;
    background-color: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 2px solid #ffffff; }
    @media only screen and (max-width: 620px) {
      .content-container__inner {
        padding: 2rem;
        border-radius: 0; } }
  .content-container .active {
    border-color: #5a74d7; }
  .content-container .error {
    border-color: #fa3959; }
  .content-container .complete {
    border-color: #39b54a; }
  .content-container .bg-primary {
    border-color: #222e64;
    background-color: #222e64; }
  .content-container .bg-primary-light {
    border-color: #5a74d7;
    background-color: #5a74d7; }
  .content-container .bg-secondary {
    border-color: #69e2d6;
    background-color: #69e2d6; }
  .content-container__title-container {
    background-color: #222e64;
    padding: 1.5rem 4rem; }
    .content-container__title-container h1 {
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 400; }

.form-field-container {
  display: block;
  text-align: left; }
  .form-field-container label {
    display: block;
    margin-bottom: 0.6rem;
    font-weight: 500;
    color: #4f4f4f; }
  .form-field-container input,
  .form-field-container select,
  .form-field-container textarea {
    background-color: #f4f4f4;
    width: 100%;
    font-family: inherit;
    font-size: 1.6rem;
    color: #3f3f3f;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    border-top: 2px solid #7c7c7c;
    border-right: 2px solid #7c7c7c;
    border-bottom: 2px solid #7c7c7c;
    border-left: 2px solid #7c7c7c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .form-field-container input:focus,
    .form-field-container select:focus,
    .form-field-container textarea:focus {
      border: 2px solid #69e2d6 !important;
      outline-offset: 0px !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
      -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
      box-shadow: 0 0 0.2rem 0 #69e2d6; }
  .form-field-container input,
  .form-field-container select {
    height: 5rem; }
  .form-field-container .form-field-error {
    display: block;
    margin-top: 0.5rem;
    color: #e8473d;
    font-size: 1.3rem; }

.form-fields-inline-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem; }

.form-actions-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  margin-top: 3rem; }

.main-navbar {
  display: grid;
  grid-template-columns: 200px auto auto;
  align-items: center;
  width: 100%;
  font-family: "Roboto", sans-serif;
  height: 6rem;
  background-color: #222e64; }
  .main-navbar.minimized {
    grid-template-columns: 80px auto auto; }
  .main-navbar__logo-link {
    color: #ffffff;
    background-color: #222e64;
    display: grid;
    height: 100%;
    justify-content: start;
    align-items: center;
    grid-template-columns: 1fr;
    padding-left: 2rem; }
  .main-navbar__page-title {
    color: #ffffff;
    display: grid;
    height: 100%;
    justify-content: start;
    align-items: center;
    grid-template-columns: auto auto;
    padding-left: 4rem; }
    @media only screen and (max-width: 800px) {
      .main-navbar__page-title {
        font-size: 1.8rem; } }
  .main-navbar__menu-icon-container {
    background: none;
    border: none;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 4rem;
    left: 3rem;
    align-items: center;
    display: flex; }
    .main-navbar__menu-icon-container span {
      color: #fff;
      opacity: 0.7;
      transition: all 0.3s ease;
      margin-left: 1.4rem;
      font-size: 1.6rem; }
    .main-navbar__menu-icon-container .menu-icon {
      font-size: 2.2rem;
      color: #5a74d7;
      transition: all 0.3s ease; }
      .main-navbar__menu-icon-container .menu-icon:hover {
        color: #f4f4f4; }
    .main-navbar__menu-icon-container:hover {
      cursor: pointer; }
      .main-navbar__menu-icon-container:hover span {
        opacity: 1; }
  .main-navbar .user-info {
    flex: 1 1 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    font-family: "Roboto", sans-serif;
    padding: 0 6.5rem; }
    .main-navbar .user-info__container {
      margin-left: 1.5rem; }
    .main-navbar .user-info__name {
      font-weight: 400;
      color: #ffffff; }
    .main-navbar .user-info__company {
      font-size: 1.2rem; }
    .main-navbar .user-info__avatar-container {
      width: 4rem;
      border-radius: 50%;
      overflow: hidden; }
    .main-navbar .user-info__picture {
      display: block;
      width: 100%; }
    .main-navbar .user-info__settings {
      color: #69e2d6;
      font-size: 1.8rem;
      transition: color 0.2s ease;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      margin-top: 0.5rem;
      margin-left: 1rem;
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start; }
      .main-navbar .user-info__settings:hover {
        color: #bef3ed; }
    .main-navbar .user-info__logout {
      cursor: pointer;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: #5a74d7; }
  .main-navbar .mobile-menu {
    display: none; }
  .main-navbar .burger-icon {
    color: #ffffff; }
  .main-navbar .navbar-logo {
    width: 15rem; }
  .main-navbar .navbar-logo-small {
    width: 2rem;
    margin-left: 1rem; }
  .main-navbar .page-title-tag {
    background-color: #39b54a;
    color: #ffffff;
    font-size: 1.5rem;
    padding: 0.8rem 1.5rem;
    border-radius: 23px;
    margin-left: 2rem;
    font-weight: 500; }
  .main-navbar .filters {
    flex: 1 1 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .main-navbar .filters__text {
      font-size: 1.2rem;
      font-weight: 700;
      margin-right: 0.8rem; }
      .main-navbar .filters__text .fa-filter {
        margin-right: 0.8rem;
        font-size: 1.6rem; }
    .main-navbar .filters__container .options {
      outline: none;
      background-color: transparent;
      border: none;
      color: inherit;
      font-style: italic; }
      .main-navbar .filters__container .options option {
        color: #222e64;
        font-style: normal; }
    .main-navbar .filters #toggle-side-nav {
      padding-top: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;
      padding-left: 1.6rem;
      margin-left: 4.5rem;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      background-color: #69e2d6;
      color: #222e64;
      font-size: 1.8rem;
      border: none;
      transition: all .2s ease-in;
      cursor: pointer; }
      .main-navbar .filters #toggle-side-nav:hover {
        background-color: #bef3ed; }
    .main-navbar .filters .close-btn {
      color: #ffffff;
      font-size: 2.8rem;
      transition: color 0.2s ease;
      margin-right: 3rem;
      margin-left: 2rem; }
      .main-navbar .filters .close-btn:hover {
        color: #69e2d6; }
  .main-navbar .project-name {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 3rem;
    color: #ffffff; }
    .main-navbar .project-name__input {
      font-family: inherit;
      border: none;
      background-color: transparent;
      font-size: 2.8rem;
      font-weight: 700;
      color: inherit; }
      .main-navbar .project-name__input::placeholder {
        color: #69e2d6; }
      .main-navbar .project-name__input:focus {
        outline-offset: 0px !important;
        outline: none !important; }
    .main-navbar .project-name__label {
      font-family: "Roboto", sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      display: block; }

@media only screen and (max-width: 620px) {
  .main-navbar {
    grid-template-columns: 1fr auto; }
  .main-navbar .minimized {
    grid-template-columns: 1fr; }
  .main-navbar__menu-icon-container {
    display: none; }
  .main-navbar .user-info {
    display: none; }
  .main-navbar .mobile-menu {
    display: block;
    padding-right: 2rem; }
  .main-navbar__page-title {
    display: none; } }

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(calc(45.2rem + 6%));
  width: 45.2rem;
  height: 100vh;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  transition: all 0.5s ease-out;
  z-index: 4;
  -webkit-box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(32, 32, 32, 0.4);
  -moz-box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(32, 32, 32, 0.4);
  box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(32, 32, 32, 0.4); }
  .sidebar__logo-link {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #222e64;
    padding: 1rem;
    height: 10rem; }
  .sidebar__searchbox {
    position: relative;
    height: 6rem;
    padding-top: 2rem;
    padding-right: 1.9rem;
    padding-bottom: 2rem;
    padding-left: 1.9rem; }
    .sidebar__searchbox__field {
      width: 100%;
      height: 100%;
      border: 1px solid #222e64;
      font-size: 1.8rem;
      font-style: italic;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      padding-right: 6rem;
      padding-left: 1.9rem;
      transition: all 0.3 ease-in; }
      .sidebar__searchbox__field:focus {
        border: 1px solid #69e2d6 !important;
        outline-offset: 0px !important;
        outline: none !important;
        -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
        -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
        box-shadow: 0 0 0.2rem 0 #69e2d6; }
    .sidebar__searchbox__btn {
      position: absolute;
      top: 2.5rem;
      right: 2.4rem;
      padding-top: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;
      padding-left: 1.6rem;
      margin-left: 4.5rem;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      background-color: #69e2d6;
      color: #222e64;
      font-size: 1.8rem;
      border: none;
      transition: all .2s ease-in;
      cursor: pointer; }
      .sidebar__searchbox__btn:hover {
        background-color: #bef3ed; }
    .sidebar__searchbox form {
      height: 100%; }
  .sidebar__section__header {
    height: 5.8rem;
    background-color: #f4f4f4;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 4.3rem;
    padding-left: 1.9rem;
    border-top: 1px solid #bfbcbc; }
    .sidebar__section__header__img {
      display: block;
      margin-right: 1.9rem; }
    .sidebar__section__header__info {
      font-weight: 700;
      text-transform: uppercase;
      color: #000000; }
    .sidebar__section__header .close-sidebar {
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin-left: auto;
      font-size: 1.8rem; }
  .sidebar__section-templates .list__link {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 4.3rem;
    padding-left: 1.9rem;
    border-top: 1px solid #bfbcbc;
    cursor: pointer;
    height: 9.5rem;
    color: #202020;
    transition: all 0.2s ease-out; }
    .sidebar__section-templates .list__link__name {
      font-family: inherit;
      font-size: 2.1rem;
      font-weight: 300;
      line-height: 2.4rem; }
    .sidebar__section-templates .list__link__type {
      font-family: inherit;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.06rem; }
    .sidebar__section-templates .list__link:last-child {
      border-bottom: 1px solid #bfbcbc; }
    .sidebar__section-templates .list__link .fa-arrow-right {
      opacity: 0;
      margin-left: auto;
      transform: translateX(-2.5rem);
      transition: all 0.2s ease-out; }
    .sidebar__section-templates .list__link:hover {
      background-color: #fcfcfc; }
      .sidebar__section-templates .list__link:hover .list__link__name,
      .sidebar__section-templates .list__link:hover .list__link__type {
        text-decoration: underline; }
      .sidebar__section-templates .list__link:hover .fa-arrow-right {
        opacity: 1;
        transform: translateX(0); }

.sidebar.active {
  transform: translateX(0); }

.sidebar-section {
  font-family: "Roboto", sans-serif;
  background-color: #f6f6f6; }
  .sidebar-section #project-client {
    width: 100%;
    font-family: inherit;
    font-size: 1.6rem;
    color: #3f3f3f;
    padding-top: 0.3rem;
    padding-right: 0.5rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    border-top: 1px solid #6c6c6c;
    border-right: 1px solid #6c6c6c;
    border-bottom: 1px solid #6c6c6c;
    border-left: 1px solid #6c6c6c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .sidebar-section #project-client:focus {
      border: 1px solid #69e2d6 !important;
      outline-offset: 0px !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
      -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
      box-shadow: 0 0 0.2rem 0 #69e2d6; }
  .sidebar-section #project-description {
    font-family: inherit;
    color: #3f3f3f;
    font-size: 1.3rem;
    font-weight: 300;
    width: 100%;
    resize: none;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .sidebar-section #project-description:focus {
      border: 1px solid #69e2d6 !important;
      outline-offset: 0px !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
      -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
      box-shadow: 0 0 0.2rem 0 #69e2d6; }
  .sidebar-section .phases-list {
    counter-reset: phases-counter;
    list-style: none;
    font-family: "Roboto", sans-serif; }
    .sidebar-section .phases-list li {
      counter-increment: phases-counter;
      position: relative;
      line-height: 2.5rem;
      font-size: 1.8rem;
      font-weight: 300; }
      .sidebar-section .phases-list li:not(:last-child) {
        margin-bottom: 1.2rem; }
      .sidebar-section .phases-list li:before {
        content: counter(phases-counter);
        font-family: inherit;
        font-size: 1.2rem;
        font-weight: 700;
        color: #5b5b5b;
        line-height: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 0;
        left: calc(-1 * 2.5rem - 18px);
        background: transparent;
        border-radius: 50%;
        text-align: center;
        border-top: 1px solid #202020;
        border-right: 1px solid #202020;
        border-bottom: 1px solid #202020;
        border-left: 1px solid #202020;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%; }
    .sidebar-section .phases-list__phase {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }

.acc-list {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  max-height: 58vh;
  overflow-y: scroll; }
  .acc-list__reviewer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
    align-items: center;
    display: grid; }
    .acc-list__reviewer__voteAnswer {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1rem;
      align-items: center; }
  .acc-list__voteAnswer {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    align-items: center; }
  .acc-list .comment-outer {
    padding: 0 0 1rem 0;
    border-bottom: 1px solid #999; }
  .acc-list__img {
    margin-right: 1rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    width: 4rem; }
  .acc-list .selected-container {
    display: grid;
    grid-template-columns: 2.4rem auto;
    grid-gap: 5px;
    align-items: center; }
  .acc-list__name {
    color: #5a74d7;
    font-weight: 900; }
    .acc-list__name .view-date,
    .acc-list__name .online,
    .acc-list__name .hour {
      font-size: 1.2rem;
      letter-spacing: 0.06rem;
      margin-left: 0.5rem; }
    .acc-list__name .view-date,
    .acc-list__name .hour {
      font-weight: 400;
      color: #7d7d7d; }
    .acc-list__name .hour {
      display: block;
      margin: 4px 0; }
    .acc-list__name .online {
      font-weight: 700;
      color: #59bc67;
      text-transform: uppercase; }
  .acc-list__vote.no-vote {
    background-color: #f4f4f4;
    font-size: 1.1rem;
    font-weight: 700;
    color: #565657;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #6c6c6c;
    border-right: 2px solid #6c6c6c;
    border-bottom: 2px solid #6c6c6c;
    border-left: 2px solid #6c6c6c; }
  .acc-list__vote.has-vote {
    background-color: #314bad;
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #314bad;
    border-right: 2px solid #314bad;
    border-bottom: 2px solid #314bad;
    border-left: 2px solid #314bad; }
  .acc-list__comment-opt {
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff; }
  .acc-list__msg .reply-name {
    color: #314bad; }
  .acc-list__comment-opt.opt-a {
    background-color: #314bad; }
  .acc-list__comment-opt.opt-b {
    background-color: #a9a9a9; }
  .acc-list__comment-opt.opt-c {
    background-color: #5a74d7; }
  .acc-list__comment-opt.opt-d {
    background-color: #6c6c6c; }
  .acc-list .add-reviewer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .acc-list .add-reviewer__btn {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      margin-right: 1rem;
      width: 4.4rem;
      height: 4.4rem;
      background-color: #f4f4f4;
      transition: all 0.2s ease; }
      .acc-list .add-reviewer__btn:hover {
        background-color: #e0e0e0; }
    .acc-list .add-reviewer__icon {
      font-size: 1.6rem;
      color: #5a74d7; }
    .acc-list .add-reviewer__text {
      font-size: 1.2rem;
      font-style: italic;
      letter-spacing: 0.06rem;
      color: #222e64; }
  .acc-list .user-online {
    border-top: 3px solid #39b54a;
    border-right: 3px solid #39b54a;
    border-bottom: 3px solid #39b54a;
    border-left: 3px solid #39b54a; }

.search-reviewer {
  max-height: 0;
  opacity: 0;
  transform: translateY(3rem);
  overflow: hidden;
  transition: all 0.2s ease;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem; }
  .search-reviewer__email {
    background-color: #f4f4f4;
    width: 100%;
    height: 5rem;
    font-family: inherit;
    font-size: 1.6rem;
    color: #3f3f3f;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    border-top: 2px solid #6c6c6c;
    border-right: 2px solid #6c6c6c;
    border-bottom: 2px solid #6c6c6c;
    border-left: 2px solid #6c6c6c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .search-reviewer__email:focus {
      border: 2px solid #69e2d6 !important;
      outline-offset: 0px !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
      -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
      box-shadow: 0 0 0.2rem 0 #69e2d6; }

.search-reviewer.visible {
  max-height: 120rem;
  opacity: 1;
  transform: translateY(0);
  margin-top: 2.5rem;
  margin-right: 2.4rem; }

.type-comment {
  max-height: 0;
  opacity: 0;
  transform: translateY(3rem);
  overflow: hidden;
  transition: all 0.2s ease;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .type-comment__opt {
    width: 5.7rem;
    height: 5rem;
    position: relative;
    border-top: 2px solid #6c6c6c;
    border-bottom: 2px solid #6c6c6c;
    border-left: 2px solid #6c6c6c;
    background-color: #f4f4f4;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .type-comment__vote {
    line-height: 2.5rem;
    margin-right: 4.2rem;
    margin-left: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .type-comment__vote.no-vote {
    font-size: 1.1rem;
    font-weight: 700;
    color: #565657;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #6c6c6c;
    border-right: 2px solid #6c6c6c;
    border-bottom: 2px solid #6c6c6c;
    border-left: 2px solid #6c6c6c; }
  .type-comment__vote.has-vote {
    background-color: #314bad;
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #314bad;
    border-right: 2px solid #314bad;
    border-bottom: 2px solid #314bad;
    border-left: 2px solid #314bad; }
  .type-comment__input {
    background-color: #f4f4f4;
    height: 5rem;
    width: 100%;
    font-family: inherit;
    font-size: 1.5rem;
    color: #3f3f3f;
    padding-right: 4.9rem;
    padding-left: 0.7rem;
    border-top: 2px solid #6c6c6c;
    border-right: 2px solid #6c6c6c;
    border-bottom: 2px solid #6c6c6c;
    border-left: 2px solid #6c6c6c;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
    .type-comment__input:focus {
      border: 2px solid #69e2d6 !important;
      outline-offset: 0px !important;
      outline: none !important;
      -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
      -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
      box-shadow: 0 0 0.2rem 0 #69e2d6; }
  .type-comment__mentions-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.8rem;
    border: 0;
    background-color: transparent;
    cursor: pointer; }
    .type-comment__mentions-btn:hover .fa-at {
      color: #314bad; }
    .type-comment__mentions-btn .fa-at {
      font-size: 1.8rem;
      color: #6c6c6c;
      transition: all 0.2s ease; }

.type-comment.visible {
  max-height: 120rem;
  opacity: 1;
  transform: translateY(0);
  margin-bottom: 2.5rem; }

.sidebar-section.no-icon {
  padding-top: 1.8rem;
  padding-right: 3rem;
  padding-bottom: 1.7rem;
  padding-left: 6.7rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .sidebar-section.no-icon label {
    margin-right: 1.5rem; }

.input-description,
.section-name {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #5b5b5b; }

.filter-options {
  margin-right: 3.5rem;
  margin-left: auto;
  font-family: "Roboto", sans-serif; }
  .filter-options .options {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: 1.1rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #7d7d7d; }

.section-title {
  margin-left: 1.3rem;
  font-size: 1.4rem; }

.no-acc {
  position: relative;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1.8rem;
  padding-right: 3rem;
  padding-bottom: 1.7rem;
  padding-left: 2.2rem; }
  .no-acc img {
    margin-right: 2rem; }

.description-spacing {
  padding-right: 6.5rem;
  padding-left: 6.5rem; }

.phases-spacing {
  padding-right: 3.1rem;
  padding-left: 6.5rem; }

.project-section-spacing {
  padding-right: 3.3rem;
  padding-left: 3.3rem; }

.sidebar-header {
  height: 6rem;
  background-color: #222e64;
  color: #ffffff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(0, 0, 0, 0.3);
  box-shadow: -1.4rem 0 2.8rem 0.1rem rgba(0, 0, 0, 0.3);
  padding-right: 3rem;
  padding-left: 2.5rem; }
  .sidebar-header__title {
    font-family: "Roboto", sans-serif;
    font-size: 2.4rem;
    font-weight: 300; }
  .sidebar-header__btn {
    color: #ffffff;
    font-size: 2.1rem;
    margin-top: 0.3rem;
    margin-left: auto; }

.phase-reviewers-spacing {
  overflow: auto; }

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem; }

.grid-container-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem; }

.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem; }

.grid-container-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem; }

.grid-container-3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 2rem; }
  @media only screen and (max-width: 620px) {
    .grid-container-3-1 {
      margin-top: 2rem;
      grid-gap: 0; } }

@media only screen and (max-width: 800px) {
  .grid-container, .grid-container-1, .grid-container-2, .grid-container-3, .grid-container-3-1 {
    grid-template-columns: 1fr 1fr; } }

@media only screen and (max-width: 620px) {
  .grid-container, .grid-container-1, .grid-container-2, .grid-container-3, .grid-container-3-1 {
    grid-template-columns: 1fr; } }

.navigation {
  width: 100%;
  height: 100vh;
  background-color: #222e64;
  background-image: url("../assets/images/landing-page-bg-v2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden; }
  .navigation__main-panel {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .navigation__main-panel .upper-logo-text {
      color: #ffffff;
      font-size: 2.4rem;
      margin-bottom: 2.6rem; }
    .navigation__main-panel .scroll-down {
      position: absolute;
      bottom: 19.7rem;
      left: 50%;
      transform: translateX(-50%);
      color: #69e2d6;
      font-size: 1.6rem; }
      .navigation__main-panel .scroll-down .down-arrow {
        animation: MoveUpDown 1s cubic-bezier(0.17, 0.67, 0.95, 0.57) infinite; }
    @media only screen and (max-width: 770px) {
      .navigation__main-panel {
        display: grid;
        grid-template-columns: 1fr; }
        .navigation__main-panel .main-logo {
          margin: auto;
          display: grid;
          max-width: 80%; }
        .navigation__main-panel .bottom-btns {
          margin: 2rem auto 0 auto; }
          .navigation__main-panel .bottom-btns .button {
            display: block;
            width: 31.5rem; } }

.main-dashboard-lp {
  min-height: calc(100% - 10rem); }

.dashboard .welcome-message {
  margin-bottom: 4rem; }
  .dashboard .welcome-message h1 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 2rem; }
  .dashboard .welcome-message p {
    margin-bottom: 1rem;
    font-weight: 500;
    color: #7d7d7d;
    display: block; }
  .dashboard .welcome-message .empty-img-container {
    padding: 2rem;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .dashboard .welcome-message .empty-img-container .empty-img {
      margin-top: 20%;
      max-height: 30rem;
      max-width: 100%; }

.dashboard .content-container:first-child {
  margin-bottom: 2rem; }

.dashboard .profile-complete-cta h3,
.dashboard .profile-complete-cta p {
  margin-bottom: 2rem;
  color: #ffffff;
  font-family: 'Roboto', sans-serif; }

.dashboard .profile-complete-cta .button {
  margin-top: 1rem; }

@media only screen and (max-width: 620px) {
  .dashboard .welcome-message {
    margin-bottom: 4rem; }
    .dashboard .welcome-message h1 {
      font-size: 2.1rem;
      font-weight: 700;
      margin-bottom: 2rem; }
    .dashboard .welcome-message p {
      margin-bottom: 1rem;
      font-weight: 500;
      color: #7d7d7d; } }

.main-container {
  overflow: none; }

.button__primary__white {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  background-color: #ffffff;
  color: #222e64;
  text-align: center;
  padding: 1.4rem 2rem;
  transition: all 0.2s ease-in;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }

.templates-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  @media only screen and (max-width: 620px) {
    .templates-container {
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; } }

.projects-list {
  color: #373737; }
  .projects-list .content-container:first-child {
    margin-bottom: 2rem; }
  .projects-list .actions-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 3rem; }
    @media only screen and (max-width: 620px) {
      .projects-list .actions-container {
        grid-template-columns: 1fr; } }
    .projects-list .actions-container .filters-container {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 3rem; }
      .projects-list .actions-container .filters-container .form-field-container {
        display: inline-block; }
        .projects-list .actions-container .filters-container .form-field-container label {
          display: block;
          margin-bottom: 0.6rem; }
        .projects-list .actions-container .filters-container .form-field-container input,
        .projects-list .actions-container .filters-container .form-field-container select,
        .projects-list .actions-container .filters-container .form-field-container textarea {
          background-color: #f4f4f4;
          width: 100%;
          font-family: inherit;
          font-size: 1.6rem;
          color: #3f3f3f;
          padding-right: 1.4rem;
          padding-left: 1.4rem;
          border-top: 2px solid #6c6c6c;
          border-right: 2px solid #6c6c6c;
          border-bottom: 2px solid #6c6c6c;
          border-left: 2px solid #6c6c6c;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px; }
          .projects-list .actions-container .filters-container .form-field-container input:focus,
          .projects-list .actions-container .filters-container .form-field-container select:focus,
          .projects-list .actions-container .filters-container .form-field-container textarea:focus {
            border: 2px solid #69e2d6 !important;
            outline-offset: 0px !important;
            outline: none !important;
            -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
            -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
            box-shadow: 0 0 0.2rem 0 #69e2d6; }
        .projects-list .actions-container .filters-container .form-field-container input,
        .projects-list .actions-container .filters-container .form-field-container select {
          height: 5rem; }
      @media only screen and (max-width: 620px) {
        .projects-list .actions-container .filters-container {
          grid-template-columns: 1fr; } }
    .projects-list .actions-container .actions {
      display: grid; }
      .projects-list .actions-container .actions .button {
        display: grid;
        align-self: center;
        justify-self: end; }
  .projects-list .project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4rem; }
  @media only screen and (max-width: 800px) {
    .projects-list .project-grid {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (max-width: 620px) {
    .projects-list .project-grid {
      grid-template-columns: 1fr; } }
  .projects-list .project-card {
    display: block;
    overflow: hidden;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: 0 0px 15px #00000024;
    transition: all 0.3s ease;
    position: relative; }
    .projects-list .project-card:hover {
      cursor: pointer;
      box-shadow: 0 20px 30px #00000024; }
    .projects-list .project-card .project-status {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #ffffff;
      padding: 0.4rem 0.8rem;
      font-size: 1.4rem;
      border-radius: 20px;
      text-transform: capitalize; }
    .projects-list .project-card .project-image-container {
      min-height: 16rem;
      max-height: 16rem;
      overflow: hidden; }
      .projects-list .project-card .project-image-container .project-image {
        min-height: 16rem;
        display: block;
        width: 100%; }
    .projects-list .project-card .project-information {
      padding: 2rem; }
      .projects-list .project-card .project-information .project-name {
        margin-bottom: 1rem;
        color: #373737; }
      .projects-list .project-card .project-information .project-client-name {
        margin-bottom: 1rem;
        color: #59bc67;
        font-weight: 700; }
      .projects-list .project-card .project-information .project-description {
        font-size: 1.5rem;
        color: #4f4f4f;
        margin-bottom: 1rem;
        min-height: 6rem; }
      .projects-list .project-card .project-information .reviewers-images {
        margin-top: 2rem; }
        .projects-list .project-card .project-information .reviewers-images .reviewer {
          width: 3rem;
          height: auto;
          border-radius: 50%; }

.create-project-form-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 4rem;
  color: #373737; }
  .create-project-form-container .create-project-selector-container .content-container__inner {
    padding: 3rem; }
  .create-project-form-container .create-project-selector-container .create-project-selections {
    display: grid;
    grid-gap: 2rem; }
    .create-project-form-container .create-project-selector-container .create-project-selections h4 {
      font-size: 1.6rem;
      margin-bottom: 0.6rem; }
    .create-project-form-container .create-project-selector-container .create-project-selections span {
      font-size: 1.4rem;
      color: #5b5b5b; }
  .create-project-form-container .create-project-form {
    display: grid;
    grid-gap: 2rem; }
    .create-project-form-container .create-project-form h3 {
      font-size: 2.1rem;
      margin-bottom: 2.4rem; }
    .create-project-form-container .create-project-form .project-complete-desc {
      margin-bottom: 3rem; }
    .create-project-form-container .create-project-form .form-field-container {
      margin-bottom: 1rem; }
      .create-project-form-container .create-project-form .form-field-container label {
        display: block;
        margin-bottom: 0.6rem; }
      .create-project-form-container .create-project-form .form-field-container input,
      .create-project-form-container .create-project-form .form-field-container select,
      .create-project-form-container .create-project-form .form-field-container textarea {
        background-color: #f4f4f4;
        width: 100%;
        font-family: inherit;
        font-size: 1.6rem;
        color: #3f3f3f;
        padding-top: 1.4rem;
        padding-right: 1.4rem;
        padding-bottom: 1.4rem;
        padding-left: 1.4rem;
        border-top: 2px solid #6c6c6c;
        border-right: 2px solid #6c6c6c;
        border-bottom: 2px solid #6c6c6c;
        border-left: 2px solid #6c6c6c;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; }
        .create-project-form-container .create-project-form .form-field-container input:focus,
        .create-project-form-container .create-project-form .form-field-container select:focus,
        .create-project-form-container .create-project-form .form-field-container textarea:focus {
          border: 2px solid #69e2d6 !important;
          outline-offset: 0px !important;
          outline: none !important;
          -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
          -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
          box-shadow: 0 0 0.2rem 0 #69e2d6; }
      .create-project-form-container .create-project-form .form-field-container input,
      .create-project-form-container .create-project-form .form-field-container select {
        height: 5rem; }
      .create-project-form-container .create-project-form .form-field-container .phases-list {
        counter-reset: phases-counter;
        list-style: none;
        font-family: "Roboto", sans-serif;
        margin: 2rem 0; }
        .create-project-form-container .create-project-form .form-field-container .phases-list li {
          counter-increment: phases-counter;
          position: relative;
          line-height: 2.5rem;
          font-size: 1.8rem;
          font-weight: 300;
          padding-left: 4rem; }
          .create-project-form-container .create-project-form .form-field-container .phases-list li:not(:last-child) {
            margin-bottom: 1.2rem; }
          .create-project-form-container .create-project-form .form-field-container .phases-list li:before {
            content: counter(phases-counter);
            font-family: inherit;
            font-size: 1.2rem;
            font-weight: 700;
            color: #5b5b5b;
            line-height: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            border-radius: 50%;
            text-align: center;
            border-top: 1px solid #202020;
            border-right: 1px solid #202020;
            border-bottom: 1px solid #202020;
            border-left: 1px solid #202020;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%; }
        .create-project-form-container .create-project-form .form-field-container .phases-list__phase {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center; }
      .create-project-form-container .create-project-form .form-field-container .acc-list {
        list-style-type: none;
        font-family: "Roboto", sans-serif;
        font-size: 1.5rem; }
        .create-project-form-container .create-project-form .form-field-container .acc-list li:not(:last-child) {
          margin-bottom: 1.2rem; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__reviewer {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment {
          position: relative;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__img {
          margin-right: 1rem;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          width: 4.4rem; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__email {
          align-items: center;
          display: grid; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__name {
          color: #5a74d7;
          font-weight: 900; }
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .view-date,
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .online,
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .hour {
            font-size: 1.2rem;
            letter-spacing: 0.06rem;
            margin-left: 0.5rem; }
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .view-date,
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .hour {
            font-weight: 400;
            color: #7d7d7d; }
          .create-project-form-container .create-project-form .form-field-container .acc-list__name .online {
            font-weight: 700;
            color: #59bc67;
            text-transform: uppercase; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__vote {
          line-height: 2.5rem;
          margin-right: 4.2rem;
          margin-left: auto; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__vote.no-vote {
          font-size: 1.1rem;
          font-weight: 700;
          color: #565657;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-top: 2px solid #6c6c6c;
          border-right: 2px solid #6c6c6c;
          border-bottom: 2px solid #6c6c6c;
          border-left: 2px solid #6c6c6c; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__vote.has-vote {
          background-color: #314bad;
          font-size: 1.1rem;
          font-weight: 700;
          color: #ffffff;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-top: 2px solid #314bad;
          border-right: 2px solid #314bad;
          border-bottom: 2px solid #314bad;
          border-left: 2px solid #314bad; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment-opt {
          position: absolute;
          left: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.1rem;
          font-weight: 700;
          color: #ffffff;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-top: 2px solid #ffffff;
          border-right: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          border-left: 2px solid #ffffff; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__msg .reply-name {
          color: #314bad; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment-opt.opt-a {
          background-color: #314bad; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment-opt.opt-b {
          background-color: #a9a9a9; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment-opt.opt-c {
          background-color: #5a74d7; }
        .create-project-form-container .create-project-form .form-field-container .acc-list__comment-opt.opt-d {
          background-color: #6c6c6c; }
        .create-project-form-container .create-project-form .form-field-container .acc-list .add-reviewer {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center; }
          .create-project-form-container .create-project-form .form-field-container .acc-list .add-reviewer__btn {
            border: 0;
            background-color: transparent;
            cursor: pointer;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            margin-right: 1rem;
            width: 4.4rem;
            height: 4.4rem;
            background-color: #f4f4f4;
            transition: all 0.2s ease; }
            .create-project-form-container .create-project-form .form-field-container .acc-list .add-reviewer__btn:hover {
              background-color: #e0e0e0; }
          .create-project-form-container .create-project-form .form-field-container .acc-list .add-reviewer__icon {
            font-size: 1.6rem;
            color: #5a74d7; }
          .create-project-form-container .create-project-form .form-field-container .acc-list .add-reviewer__text {
            font-size: 1.2rem;
            font-style: italic;
            letter-spacing: 0.06rem;
            color: #222e64; }
        .create-project-form-container .create-project-form .form-field-container .acc-list .user-online {
          border-top: 3px solid #39b54a;
          border-right: 3px solid #39b54a;
          border-bottom: 3px solid #39b54a;
          border-left: 3px solid #39b54a; }
      .create-project-form-container .create-project-form .form-field-container input[type=file] {
        height: 8rem;
        margin-bottom: 1rem; }
      .create-project-form-container .create-project-form .form-field-container input[type=file]::file-selector-button {
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 1.4rem;
        text-align: center;
        min-width: 14rem;
        padding: 1.3rem 2.2rem;
        transition: all 0.2s ease;
        border-radius: 3px;
        margin-bottom: 1rem;
        background-color: transparent;
        color: #222e64;
        border: 2px solid #222e64;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px; }
        .create-project-form-container .create-project-form .form-field-container input[type=file]::file-selector-button:hover {
          cursor: pointer;
          background-color: #222e64;
          color: #ffffff; }
          .create-project-form-container .create-project-form .form-field-container input[type=file]::file-selector-button:hover:disabled {
            background-color: transparent;
            color: #222e64; }
        .create-project-form-container .create-project-form .form-field-container input[type=file]::file-selector-button:disabled {
          opacity: 0.5;
          cursor: not-allowed; }
      .create-project-form-container .create-project-form .form-field-container .upload-img__preview {
        display: block;
        width: 100%;
        background-color: #f4f4f4;
        border-top: 2px solid #222e64;
        border-right: 2px solid #222e64;
        border-left: 2px solid #222e64; }

@media only screen and (max-width: 800px) {
  .create-project-form-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr; } }

.two-col-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.add-templates {
  height: calc(100vh - 6rem);
  overflow-y: auto;
  -webkit-flex-basis: 100%;
  flex-basis: 100%; }

.page-header {
  background-color: #222e64;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 5px; }
  .page-header__img {
    margin-right: 2.5rem; }
  .page-header__info {
    font-family: inherit; }
  .page-header__template-name-label {
    color: #69e2d6;
    font-weight: 700;
    letter-spacing: 0.022rem;
    font-family: "Roboto", sans-serif; }
  .page-header__template-name {
    font-size: 2.4rem;
    font-weight: 100;
    margin-top: 0.3rem;
    margin-bottom: 0.7rem;
    background-color: #ffffff1f;
    font-family: "Roboto", sans-serif;
    display: block;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    color: #fff;
    padding: 1rem; }
    .page-header__template-name:focus {
      outline-offset: 0px !important;
      outline: none !important; }
    .page-header__template-name::placeholder {
      color: #ffffffa3; }
    .page-header__template-name:-ms-input-placeholder {
      color: #ffffffa3; }
    .page-header__template-name::-ms-input-placeholder {
      color: #ffffffa3; }
  .page-header__additional-options {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .page-header__additional-options .category {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .page-header__additional-options .category__icon {
        padding-right: 1rem; }
      .page-header__additional-options .category__name {
        padding-right: 0.5rem;
        font-family: inherit;
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.6rem; }
      .page-header__additional-options .category__options {
        margin-right: 2.5rem;
        font-family: inherit;
        border: none;
        background-color: transparent;
        color: inherit;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.07rem; }
        .page-header__additional-options .category__options:focus {
          outline: none; }
        .page-header__additional-options .category__options option {
          color: #222e64; }
    .page-header__additional-options .tags {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .page-header__additional-options .tags__icon {
        padding-right: 1rem; }
      .page-header__additional-options .tags__name {
        padding-right: 0.5rem;
        font-family: inherit;
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.6rem; }
      .page-header__additional-options .tags__names {
        font-family: inherit;
        border: none;
        background-color: transparent;
        color: inherit;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.07rem; }
        .page-header__additional-options .tags__names::placeholder {
          color: #ffffff; }
        .page-header__additional-options .tags__names:-ms-input-placeholder {
          color: #ffffff; }
        .page-header__additional-options .tags__names::-ms-input-placeholder {
          color: #ffffff; }
        .page-header__additional-options .tags__names:focus {
          outline: none; }
  .page-header__close-btn {
    margin-left: auto;
    color: #ffffff;
    font-size: 2.8rem;
    transition: color 0.2s ease;
    margin-right: 3rem; }
    .page-header__close-btn:hover {
      color: #69e2d6; }

.template-options-container {
  height: calc(100vh - 15.6rem - 10.3rem);
  overflow-y: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.template-questions-spacing {
  padding-right: 2.2rem;
  padding-left: 2.2rem; }

.template-options__add-question .add-question-container button {
  margin-top: 2rem; }
  .template-options__add-question .add-question-container button i {
    margin-right: 1rem; }

.template-options__add-question .add-question {
  height: 6rem;
  width: 6rem;
  color: #5a74d7;
  font-size: 2.1rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  border-top: 2px solid #5a74d7;
  border-right: 2px solid #5a74d7;
  border-bottom: 2px solid #5a74d7;
  border-left: 2px solid #5a74d7;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin-right: 4rem; }
  .template-options__add-question .add-question:hover {
    background-color: #5a74d7;
    color: #ffffff; }

.template-options__add-question .choose-question {
  margin: 1rem 0;
  padding: 2rem;
  background-color: #f4f4f4;
  border-radius: 3px; }
  .template-options__add-question .choose-question__options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem; }

.template-options__add-question .not-visible {
  margin: 0;
  padding: 0; }

.template-options__add-question p {
  font-weight: 700;
  margin-bottom: 2rem; }

.template-footer {
  height: 10.3rem;
  background-color: #f4f4f4;
  color: #ffffff;
  margin-top: auto;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 1rem;
  padding-right: 1.2rem;
  padding-bottom: 1rem;
  padding-left: 3.3rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .template-footer__save-draft-btn {
    display: inline-block;
    width: 19.1rem;
    height: 5.8rem;
    background-color: transparent;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    transition: all 0.2s ease;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .template-footer__save-draft-btn:hover {
      background-color: #ffffff;
      color: #222e64; }
    .template-footer__save-draft-btn .fa-save {
      margin-right: 1.3rem; }
  .template-footer__toggle-visibility-btn {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 2.9rem;
    margin-left: auto;
    color: #ffffff;
    font-size: 1.4rem; }
    .template-footer__toggle-visibility-btn .fas {
      font-size: 2.5rem;
      margin-right: 1rem; }
  .template-footer__create-template-btn {
    display: inline-block;
    width: 31.6rem;
    height: 8.1rem;
    font-size: 2.1rem;
    font-weight: 700;
    color: #373737;
    background-color: #69e2d6;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .template-footer__create-template-btn:active {
      color: #373737; }
    .template-footer__create-template-btn:hover {
      background-color: #a9eee8; }

.template-menu-container {
  position: relative; }
  .template-menu-container .template-menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(34, 46, 100, 0.9);
    z-index: 3;
    min-height: calc(100vh - 6rem);
    width: 100%; }

.phase-review .content-container {
  margin-bottom: 2rem; }

.logo-selection__header {
  padding-right: 5.3rem;
  margin-bottom: 4rem; }

.logo-selection__header-inner {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin-bottom: 2rem; }

.logo-selection__options {
  min-height: 23rem;
  margin: 0 auto 4rem auto; }

.logo-selection__question {
  font-size: 2.8rem;
  font-weight: 500; }
  @media only screen and (max-width: 620px) {
    .logo-selection__question {
      font-size: 2.1rem; } }

.logo-selection__description {
  margin-bottom: 1rem;
  font-weight: 500;
  color: #7d7d7d;
  font-size: 1.8rem; }

.logo-selection__actions-container {
  margin-top: 8rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between; }

.logo-selection__display-type {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  background: #e0e1e9;
  padding: 1rem 1.5rem;
  border-radius: 5px; }
  .logo-selection__display-type p {
    font-size: 1.3rem;
    font-family: inherit;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 2.1rem; }
  .logo-selection__display-type .grid-view-btn {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-right: 1.3rem; }
  .logo-selection__display-type .stacked-view-btn {
    border: 0;
    background-color: transparent;
    cursor: pointer; }

.logo-selection__col {
  transition: all 0.3s ease; }
  .logo-selection__col:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px #00000024; }
  .logo-selection__col img {
    max-height: 14rem;
    margin: auto;
    display: block;
    background-color: #ffffff;
    cursor: pointer;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem; }

.logo-selection__col-1 {
  height: 10rem;
  background-color: #222e640d;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 5px 10px #00000014; }
  .logo-selection__col-1.image-col {
    height: 40rem; }
    .logo-selection__col-1.image-col img {
      max-width: 50%;
      margin: 0;
      padding: 0; }
    .logo-selection__col-1.image-col .logo-options {
      margin: 0; }
  .logo-selection__col-1.selected {
    background-color: #222e6424;
    box-shadow: 0 10px 20px #00000024;
    border-top: 2px solid #5a74d7;
    border-right: 2px solid #5a74d7;
    border-bottom: 2px solid #5a74d7;
    border-left: 2px solid #5a74d7; }
    .logo-selection__col-1.selected .logo-options__text {
      color: #373737; }
  .logo-selection__col-1 .logo-options {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 2rem;
    height: 100%;
    align-items: center; }
    .logo-selection__col-1 .logo-options__btn {
      font-size: 2.1rem;
      font-weight: 700;
      color: #565657;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-top: 2px solid #6c6c6c;
      border-right: 2px solid #6c6c6c;
      border-bottom: 2px solid #6c6c6c;
      border-left: 2px solid #6c6c6c; }
    .logo-selection__col-1 .logo-options__comment {
      font-size: 2.4rem;
      margin-top: 1rem; }
    .logo-selection__col-1 .logo-options__text {
      margin-top: 1rem;
      font-weight: 500;
      color: #7d7d7d; }

.logo-selection__col-2 {
  height: 10rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 5px 10px #00000014;
  overflow: hidden; }
  .logo-selection__col-2.image-col {
    height: 20rem; }
    .logo-selection__col-2.image-col img {
      max-height: 100%;
      margin: 0;
      padding: 0; }
    .logo-selection__col-2.image-col .logo-options {
      margin: 0; }
  .logo-selection__col-2.selected {
    background-color: #222e6424;
    box-shadow: 0 10px 20px #00000024;
    border-top: 2px solid #5a74d7;
    border-right: 2px solid #5a74d7;
    border-bottom: 2px solid #5a74d7;
    border-left: 2px solid #5a74d7; }
    .logo-selection__col-2.selected .logo-options__text {
      color: #373737; }
  .logo-selection__col-2 .logo-options {
    margin-top: 0.4rem;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
    align-items: center; }
    .logo-selection__col-2 .logo-options__btn {
      font-size: 1.1rem;
      font-weight: 700;
      color: #565657;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-top: 2px solid #6c6c6c;
      border-right: 2px solid #6c6c6c;
      border-bottom: 2px solid #6c6c6c;
      border-left: 2px solid #6c6c6c;
      margin-left: 1.3rem; }
    .logo-selection__col-2 .logo-options__comment {
      font-size: 2.1rem;
      margin-left: 1.2rem; }
    .logo-selection__col-2 .logo-options__text {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: 1.2rem;
      font-weight: 500;
      color: #7d7d7d;
      height: 100%;
      width: calc(100% - 50px); }
    .logo-selection__col-2 .logo-options__perc {
      opacity: 0.6;
      margin-left: 10px; }

.logo-selection .logo-options {
  font-family: "Roboto", sans-serif; }
  .logo-selection .logo-options__vote-text {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #7d7d7d;
    margin-bottom: 1rem; }
  .logo-selection .logo-options__btn {
    cursor: pointer; }
  .logo-selection .logo-options__btn.selected {
    background-color: #314bad;
    color: #ffffff;
    border-color: #314bad; }
  .logo-selection .logo-options__comment {
    color: #a9a9a9;
    cursor: pointer; }
  .logo-selection .logo-options__comment.enabled {
    color: #314bad; }

.logo-selection .textarea {
  min-height: 10px;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 10rem;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #7d7d7d;
  border-top: 2px solid #202020;
  border-right: 2px solid #202020;
  border-bottom: 2px solid #202020;
  border-left: 2px solid #202020;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }
  .logo-selection .textarea:focus {
    border: 1px solid #69e2d6 !important;
    outline-offset: 0px !important;
    outline: none !important;
    -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
    -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
    box-shadow: 0 0 0.2rem 0 #69e2d6; }

.two-col-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 4rem; }

.logo-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center; }
  .logo-wrapper img {
    max-width: 20rem;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem; }

.votes-wrapper {
  padding: 0 2rem; }

@media only screen and (max-width: 620px) {
  .logo-selection__display-type {
    display: none; }
  .logo-selection__header {
    padding-right: 0;
    margin-bottom: 4rem; }
  .logo-selection__col-1 {
    height: 7rem; }
  .logo-selection__options {
    min-height: auto;
    margin: 0; }
  .logo-selection__actions-container {
    margin-top: 4rem; } }

.templates-list {
  color: #373737; }
  .templates-list .content-container:first-child {
    margin-bottom: 2rem; }
  .templates-list .actions-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 3rem; }
    .templates-list .actions-container .filters-container {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 3rem; }
      .templates-list .actions-container .filters-container .form-field-container {
        display: inline-block; }
        .templates-list .actions-container .filters-container .form-field-container label {
          display: block;
          margin-bottom: 0.6rem; }
        .templates-list .actions-container .filters-container .form-field-container input,
        .templates-list .actions-container .filters-container .form-field-container select,
        .templates-list .actions-container .filters-container .form-field-container textarea {
          background-color: #f4f4f4;
          width: 100%;
          font-family: inherit;
          font-size: 1.6rem;
          color: #3f3f3f;
          padding-right: 1.4rem;
          padding-left: 1.4rem;
          border-top: 2px solid #6c6c6c;
          border-right: 2px solid #6c6c6c;
          border-bottom: 2px solid #6c6c6c;
          border-left: 2px solid #6c6c6c;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px; }
          .templates-list .actions-container .filters-container .form-field-container input:focus,
          .templates-list .actions-container .filters-container .form-field-container select:focus,
          .templates-list .actions-container .filters-container .form-field-container textarea:focus {
            border: 2px solid #69e2d6 !important;
            outline-offset: 0px !important;
            outline: none !important;
            -webkit-box-shadow: 0 0 0.2rem 0 #69e2d6;
            -moz-box-shadow: 0 0 0.2rem 0 #69e2d6;
            box-shadow: 0 0 0.2rem 0 #69e2d6; }
        .templates-list .actions-container .filters-container .form-field-container input,
        .templates-list .actions-container .filters-container .form-field-container select {
          height: 5rem; }
    .templates-list .actions-container .actions {
      display: grid; }
      .templates-list .actions-container .actions .button {
        display: grid;
        align-self: center;
        justify-self: end; }
  .templates-list .template-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4rem; }
  .templates-list .template-card {
    display: block;
    overflow: hidden;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: 0 0px 15px #00000024;
    transition: all 0.3s ease; }
    .templates-list .template-card:hover {
      cursor: pointer;
      box-shadow: 0 20px 30px #00000024; }
    .templates-list .template-card .template-image-container {
      min-height: 16rem;
      max-height: 16rem;
      overflow: hidden; }
      .templates-list .template-card .template-image-container .template-image {
        min-height: 16rem;
        display: block;
        width: 100%; }
    .templates-list .template-card .template-information {
      padding: 2rem; }
      .templates-list .template-card .template-information .template-name {
        margin-bottom: 1rem;
        color: #373737; }
      .templates-list .template-card .template-information .template-client-name {
        margin-bottom: 1rem;
        color: #59bc67;
        font-weight: 700; }
      .templates-list .template-card .template-information .template-description {
        font-size: 1.5rem;
        color: #4f4f4f;
        margin-bottom: 1rem;
        min-height: 6rem; }
      .templates-list .template-card .template-information .reviewers-images {
        margin-top: 2rem; }
        .templates-list .template-card .template-information .reviewers-images .reviewer {
          width: 3rem;
          height: auto; }

.profile {
  color: #373737; }
  .profile .profile-grid {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-gap: 6rem;
    height: 100%; }
  .profile .profile-content {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 4rem; }
    .profile .profile-content h2 {
      margin-bottom: 1rem;
      font-size: 3.2rem;
      font-weight: 400; }
    .profile .profile-content p {
      margin: 0.5rem 0; }
  .profile__input {
    width: 31.5rem;
    font-size: 2.1rem;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: inherit;
    background-color: transparent;
    color: #4f4f4f;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .profile__input:focus {
      outline: none; }
  .profile__actions-container {
    margin-top: 2rem; }
    .profile__actions-container .button:not(:last-child) {
      margin-right: 2rem; }
  .profile .right-container {
    display: grid;
    grid-template-rows: 1.5fr 3fr;
    grid-gap: 4rem; }
  .profile .upgrade-container {
    color: #ffffff; }
  .profile .reviews-container {
    background-color: #fff; }

.profile-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4rem; }

.avatar-container {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  overflow: hidden; }
  .avatar-container img {
    width: 100%; }

.account-type-container p {
  margin-bottom: 2rem; }

.account-type-container ul {
  margin-bottom: 2rem;
  margin-left: 1.5rem; }

.onboarding {
  height: 100vh;
  background-color: #222e64;
  color: #373737; }
  .onboarding .page-container {
    display: grid;
    justify-content: center;
    align-items: center; }
  .onboarding__inner {
    min-width: 65vw; }
  .onboarding__form-container {
    max-width: 45rem;
    margin: 4rem auto 0 auto; }
    .onboarding__form-container .form-field-container {
      margin-bottom: 1.6rem; }
  .onboarding__center-container {
    text-align: center; }
    .onboarding__center-container h1 {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 2rem; }
    .onboarding__center-container p {
      margin-bottom: 1rem;
      font-weight: 500;
      color: #7d7d7d; }
  .onboarding__roles-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: 4rem auto 2rem auto;
    max-width: 54rem; }
    .onboarding__roles-container .role-option {
      padding: 2.2rem;
      min-height: 16rem;
      background-color: #222e640d;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      box-shadow: 0 5px 10px #00000014;
      display: grid;
      text-align: left;
      border-top: 2px solid transparent;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent; }
      .onboarding__roles-container .role-option .main-label {
        font-size: 1.8rem;
        color: #373737;
        font-weight: 500; }
      .onboarding__roles-container .role-option .price-label {
        font-weight: 400;
        margin-top: 1rem; }
      .onboarding__roles-container .role-option ul {
        list-style: none;
        margin-top: 2.4rem; }
        .onboarding__roles-container .role-option ul li {
          font-size: 1.4rem;
          margin-top: 1rem; }
          .onboarding__roles-container .role-option ul li .fas {
            font-size: 1rem;
            margin-right: 0.8rem;
            color: #fff;
            background-color: #373737;
            padding: 4px;
            border-radius: 3px; }
    .onboarding__roles-container .role-option:hover {
      cursor: pointer; }
    .onboarding__roles-container .selected {
      background-color: #222e6424;
      box-shadow: 0 10px 20px #00000024;
      border-top: 2px solid #5a74d7;
      border-right: 2px solid #5a74d7;
      border-bottom: 2px solid #5a74d7;
      border-left: 2px solid #5a74d7; }
      .onboarding__roles-container .selected ul li .fas {
        background-color: #5a74d7; }
  .onboarding__icon {
    font-size: 12rem;
    color: #5a74d7;
    margin: 3rem 0; }
  .onboarding__actions-container {
    display: inline-grid;
    margin-top: 2rem; }
